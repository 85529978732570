<template>
    <div class="ph-container">
        <v-container fluid class="ph-nonex-container">
            <v-row class="ma-3">
                <v-col cols="12">
                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :headers="headers"
                            :items="financeList"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            :search = "search"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-layout>
                                        <v-spacer></v-spacer>
                                        <v-flex md4 class="search">
                                            <v-text-field
                                                v-model="search"
                                                placeholder="Search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field> 
                                        </v-flex>
                                    </v-layout>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.sp_primary_details.account_name`]="{item}">
                                <div style="cursor: pointer;" @click="$router.push({ path: 'sales-prospecting/view-profile/' + item.id })">
                                    {{ item.sp_primary_details.account_name }}
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{item}">
                                {{formatDate(item.created_at)}}
                            </template>
                            <template v-slot:[`item.action`]="{item}">
                                <div>

                                    <v-btn
                                        v-if="item.finance_status == 'Finance Pending (Initial)' || item.finance_status == 'New'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/finance/'+item.id})"
                                        >
                                        <v-icon>mdi-clipboard-text</v-icon>
                                    </v-btn>

                                    <v-btn
                                        v-if="item.finance_status == 'Finance Pending (Final)'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/finance/'+item.id})"
                                        >
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>

                                </div>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'

export default {
  name: 'Users',
  components:{
  },
  data () {
    return {
        search: '',
        financeList: [],
        headers: [
            {
                text: 'Account Name',
                sortable: true,
                value: 'sp_primary_details.account_name'
            },
            {
                text: 'BP ID Number',
                sortable: true,
                value: 'bp_idno'
            },
            {
                text: 'SP Number',
                sortable: true,
                value: 'prospective_number'
            },
            {
                text: 'Owner',
                sortable: true,
                value: 'created_by'
            },
            {
                text: 'Charges/Commission Status',
                sortable: true,
                value: 'finance_status'
            },
            {
                text: 'Bank Status',
                sortable: true,
                value: 'bank_status'
            },
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                align: 'center'
            }
        ],
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  async mounted () {
    const res = await this.$store.dispatch('finance/doGetFinanceList')
    console.log(res)
    res.data.result.map( e=> {
        if (e.finance_status == 'Finance Pending (Initial)' || e.finance_status == 'Finance Pending (Final)'){
            this.financeList.push(e)
        }
    })
    this.$store.commit('finance/SET_IS_DEFAULT')
  },
  methods: {
    formatDate (date) {
        return format(parseISO(date), 'yyyy-MM-dd')
    },
  }
}
</script>

<style scoped>
.initial-status {
  color: blue;
}

.final-status {
  color: green;
}
</style>